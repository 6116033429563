import React from "react"
import { AvForm, AvCheckboxGroup, AvCheckbox } from "availity-reactstrap-validation"
import { numberWithSpaces } from "../utils"
import { Link } from "react-router-dom"
const userAgent = navigator.userAgent || navigator.vendor
const android = /android/i.test(userAgent)
const baseUri = process.env.REACT_APP_BASEURI
export interface IApprovalInfo {
    Summa?: string //Сумма займа
    BegDate?: string //Дата выдачи
    EndDate?: string //Дата возврата
    SummaPay?: string //Сумма возврата
    CountDay?: string //Срок займа
    SummaHands?: string //Сумма к получению
}
interface ILoan {
    info: IApprovalInfo
    handleSubmit: (values: any) => void
}

const Loan: React.FC<ILoan> = ({ info, ...props }) => {
    const handleSubmit = async (e: React.FormEvent<HTMLInputElement>, errors: any, values: any) => {
        e.preventDefault()
        if (!errors || !errors.length) {
            props.handleSubmit(values)
        }
    }
    const { Summa, CountDay, SummaHands, SummaPay, EndDate } = info
    return (
        <>
            <h3 className="mb-0">Получение займа</h3>
            <AvForm onSubmit={handleSubmit} className="form-signin">
                <div>Поздравляем! Ваша заявка одобрена.</div>
                <div>
                    Для завершения оформления заявки необходимо ознакомиться с условиями договора оферты и подтвердить
                    согласие с ним кодом из смс.
                </div>
                <div className="mt-3">
                    <ul style={{ paddingLeft: "20px" }}>
                        <li>Сумма займа: {numberWithSpaces(Summa)} руб.</li>
                        <li>Срок займа: {CountDay} дней</li>
                        <li>Вы получите: {numberWithSpaces(SummaHands)} руб.</li>
                        <li>До (включительно): {EndDate} года</li>
                        <li>Вернуть: {SummaPay && `${numberWithSpaces(SummaPay)} руб.`} </li>
                    </ul>
                </div>
                <AvCheckboxGroup
                    name="personalData"
                    required
                    errorMessage="Пожалуйста ознакомьтесь и подтвердите своё согласие"
                >
                    <AvCheckbox
                        customInput
                        label={
                            <>
                                Согласен с условиями{" "}
                                {android ? (
                                    <Link to="pdf/contract.pdf">Скачать договор</Link>
                                ) : (
                                    <a
                                        href={`${baseUri}/api/newContract.pdf`}
                                        role="button"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        договора-оферты
                                    </a>
                                )}
                            </>
                        }
                        value={true}
                    />
                </AvCheckboxGroup>
                <button className="btn btn-lg btn-da btn-block mt-3" type="submit">
                    Подтвердить
                </button>
            </AvForm>
        </>
    )
}

export default Loan

export const numberWithSpaces = (x: string | number | undefined): string => {
    if (!x) {
        return ""
    }
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")
}

export const getErrorMessage = (code: number) => {
    switch (code) {
        case 400:
            return "Неверный запрос"
        case 401:
            return "Ошибка автоизации.Неверные логин или пароль"
        case 403:
            return "Неверный код смс"
        case 404:
            return "Пользователь не найден"
        case 406:
            return "Не найдена заявка для подтверждения"
        case 409:
            return "Конфликт статусов"
        case 417:
            return "Не правильно сформирован запрос на сервер"
        case 423:
            return "Время жизни смс кода истекло"
        case 424:
            return "Не все данные получены в верном фрормате"
        case 503:
            return "Не удается отправить смс"

        case 500:
        default:
            return "Внутреняя ошибка сервера"
    }
}

import React, { useEffect, useState } from "react"
import { Link, useHistory } from "react-router-dom"
import { AvForm, AvGroup, AvInput, AvFeedback } from "availity-reactstrap-validation"
import { FormGroup, Alert, Label } from "reactstrap"
import { Helmet } from "react-helmet"

import logo from "../img/logo-da.png"

const baseUri = process.env.REACT_APP_BASEURI
const headers = {
    "Content-Type": "application/json",
    Pragma: "no-cache",
    "Cache-Control": "no-cache",
}

const Login: React.FC = () => {
    const history = useHistory()
    const [error, setError] = useState("")

    useEffect(() => {
        const signIn = localStorage.getItem("signIn")
        if (signIn) {
            return history.push("/lk")
        }
        document.body.className = "login"
    })

    const handleSubmit = async (e: React.FormEvent<HTMLInputElement>, errors: Array<string>, values: any) => {
        if (!errors || !errors.length) {
            try {
                const res = await fetch(`${baseUri}/api/login`, {
                    method: "POST",
                    headers,
                    credentials: "include",
                    body: JSON.stringify(values),
                })

                switch (res.status) {
                    case 200:
                        const { signIn } = await res.json()
                        localStorage.setItem("signIn", signIn)

                        return history.push("/lk")

                    case 400:
                    case 404:
                    case 401:
                        return setError("Неверный логин или пароль")

                    case 500:
                    default:
                        return setError("Сервер авторизации не отвечает. Попробуйте авторизоваться позже")
                }
            } catch (err) {
                console.error(err)
                return setError("Сервер авторизации не отвечает. Попробуйте авторизоваться позже")
            }
        }
    }

    return (
        <>
            <Helmet>
                <title>Вход в личный кабинет "ДеньгиАктив"</title>
            </Helmet>
            <AvForm onSubmit={handleSubmit} className="form-signin bg-da border p-4" autoComplete="true">
                <img src={logo} alt="ДеньгиАктив" style={{ width: "100%" }} className="mb-4" />
                <AvGroup className="form-label-group">
                    <AvInput type="text" name="login" id="login" placeholder="Логин" autoFocus={true} required />
                    <Label for="login">Логин</Label>
                    <AvFeedback>Обязательное поле</AvFeedback>
                </AvGroup>
                <AvGroup className="form-label-group">
                    <AvInput type="password" name="password" id="password" placeholder="Пароль" required />
                    <Label for="password">Пароль</Label>
                    <AvFeedback>Обязательное поле</AvFeedback>
                </AvGroup>
                {error && <Alert color="danger">{error}</Alert>}
                <FormGroup className="d-flex flex-row justify-content-between align-items-end form-group">
                    <Link className="recovery-link" to="/recovery">
                        Забыли пароль?
                    </Link>
                    <button className="btn-da btn">Войти</button>
                </FormGroup>
            </AvForm>
        </>
    )
}

export default Login

import React, { useState, useEffect } from "react"
import { ModalBody } from "reactstrap"

const ZKFrame: React.FC<{ query: string }> = ({ query }) => {
    const [iframeHeight, setIframeHeight] = useState<number | undefined>(965)

    useEffect(() => {
        const message = (event: any) => {
            setIframeHeight((height: any) => {
                if (event.data !== height) {
                    return event.data
                }
                return height
            })
        }

        window.addEventListener("message", message)

        return () => window.removeEventListener("message", message)
    }, [])

    return (
        <ModalBody style={{ height: `${iframeHeight && iframeHeight + 32}px` }}>
            <iframe
                id="iframe"
                src={`https://koronapay.com/repayment/online/alekta?${query}`}
                width="100%"
                height="100%"
                frameBorder="0"
                allowFullScreen
            />
        </ModalBody>
    )
}

export default ZKFrame

import React from "react"
import { BrowserRouter as Router, Switch, Route } from "react-router-dom"
import Login from "./components/Login"
import Recovery from "./components/Recovery"
import LK from "./components/LK"
import Contract from "./components/Contract"

const App: React.FC = () => {
    return (
        <Router>
            <Switch>
                <Route exact path="/">
                    <Login />
                </Route>
                <Route path="/recovery">
                    <Recovery />
                </Route>
                <Route path="/lk">
                    <LK />
                </Route>
                <Route path="/pdf/:file" component={Contract} />
            </Switch>
        </Router>
    )
}

export default App

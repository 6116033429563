import React, { useState, useEffect } from "react"
import { AvForm, AvGroup, AvInput, AvFeedback } from "availity-reactstrap-validation"
import { Label, FormGroup, Input, Alert } from "reactstrap"
import InputMask from "react-input-mask"
import { Helmet } from "react-helmet"
import { Link } from "react-router-dom"
import { differenceInMinutes } from "date-fns"

import logo from "../img/logo-da.png"

const baseUri = process.env.REACT_APP_BASEURI

const headers = {
    "Content-Type": "application/json",
    Pragma: "no-cache",
    "Cache-Control": "no-cache",
}

const Recovery: React.FC = () => {
    const [error, setError] = useState("")
    const [message, setMessage] = useState("")

    useEffect(() => {
        document.body.className = "login"
    }, [])

    const handleSubmit = async (
        e: React.FormEvent<HTMLInputElement>,
        errors: Array<string>,
        { phone, passport }: any,
    ) => {
        if (!errors || !errors.length) {
            try {
                const res = await fetch(`${baseUri}/api/recovery`, {
                    method: "POST",
                    headers,
                    credentials: "include",
                    body: JSON.stringify({ phone: phone.replace(/\D/gi, "").substr(1), passport }),
                })

                switch (res.status) {
                    case 200:
                        const { StatusSMS, ttl } = await res.json()
                        if (ttl) {
                            setError("")
                            const date = new Date(ttl)
                            const now = new Date()
                            const minutes = differenceInMinutes(date, now)
                            return setMessage(`СМС можно будет запросит через ${minutes} мин.`)
                        }
                        if (StatusSMS === "OK") {
                            setError("")
                            return setMessage("СМС с паролем отправлена на ваш телефон.")
                        }
                        return setError("Неверный телефон или паспорт.")

                    case 403:
                        try {
                            const { message } = await res.json()
                            setMessage("")
                            return setError(message || "Сервер не отвечает. Попробуйте восстановить доступ позже.")
                        } catch (err) {
                            setMessage("")
                            return setError("Сервер не отвечает. Попробуйте восстановить доступ позже.")
                        }
                    case 400:
                    case 404:
                    case 401:
                        setMessage("")
                        return setError("Неверный телефон или паспорт.")

                    case 500:
                    default:
                        setMessage("")
                        return setError("Сервер не отвечает. Попробуйте восстановить доступ позже.")
                }
            } catch (err) {
                console.error(err)
                return setError("Сервер не отвечает. Попробуйте восстановить доступ позже.")
            }
        }
    }

    return (
        <>
            <Helmet>
                <title>Восстановление пароля "ДеньгиАктив"</title>
            </Helmet>
            <AvForm onSubmit={handleSubmit} className="form-signin bg-da border p-4">
                <img src={logo} alt="ДеньгиАктив" style={{ width: "100%" }} className="mb-4" />
                <AvGroup className="form-label-group">
                    <AvInput
                        type="text"
                        mask="+7 (999) 999-9999"
                        maskChar="_"
                        tag={[Input, InputMask]}
                        name="phone"
                        id="phone"
                        placeholder="Телефон"
                        label="Телефон"
                        autoFocus={true}
                        // pattern="(\+?\d[- .]*){11}"
                        pattern="^\+7\s\(\d{3}\)\s\d{3}\-\d{4}$"
                        required
                    />
                    <Label for="phone">Телефон</Label>
                    <AvFeedback>Обязательное поле</AvFeedback>
                </AvGroup>
                <AvGroup className="form-label-group">
                    <AvInput type="text" name="passport" id="passport" placeholder="Паспортные даннные" required />
                    <Label for="passport">Паспортные даннные</Label>
                    <AvFeedback>Обязательное поле</AvFeedback>
                </AvGroup>
                {error && <Alert color="danger">{error}</Alert>}
                {message && <Alert color="warning">{message}</Alert>}
                <FormGroup className="d-flex flex-row justify-content-between align-items-end form-group">
                    <Link className="recovery-link" to="/">
                        Назад
                    </Link>
                    <button className="btn-da btn">Восстановить</button>
                </FormGroup>
            </AvForm>
        </>
    )
}

export default Recovery

import React, { useEffect, useState, MouseEvent } from "react"
import { AvForm, AvGroup, AvInput, AvFeedback } from "availity-reactstrap-validation"
import { Label, Alert } from "reactstrap"

interface ILoanConfirmation {
    handleSubmit: (values: any, type: string) => void
    handleGetCode: (type: string) => void
    text: React.ReactElement
    label: string
    error: string
    message: string
    type: string
}

const LoanConfirmation: React.FC<ILoanConfirmation> = ({ label, text, error, message, type, ...props }) => {
    const [enable, setEnable] = useState(false)

    useEffect(() => {
        window.setTimeout(() => setEnable(true), 120000)
    }, [enable])

    const handleSubmit = (e: React.FormEvent<HTMLInputElement>, errors: any, values: any) => {
        e.preventDefault()
        if (!errors || !errors.length) {
            props.handleSubmit(values, type)
        }
    }

    const handleGetCode = (e: MouseEvent<HTMLButtonElement>) => {
        e.preventDefault()
        props.handleGetCode(type)
        setEnable(false)
    }

    return (
        <>
            <h3 className="mb-0">{label}</h3>
            <AvForm onSubmit={handleSubmit} className="form-signin">
                {text}
                <AvGroup className="form-label-group mt-3">
                    <AvInput type="text" id="code" name="code" placeholder="Код из СМС" required />
                    <Label for="code">Код из СМС</Label>
                    <AvFeedback>Введите код из смс</AvFeedback>
                </AvGroup>
                <button className="btn btn-link text-center w-100" disabled={!enable} onClick={handleGetCode}>
                    Получить код повторно
                </button>
                <div className="mb-2">
                    Если в течении 2 минут не прийдет смс с кодом, получите код повторно. Действия смс кода 5 минут.
                </div>
                {error && <Alert color="danger">{error}</Alert>}
                {message && <Alert>{message}</Alert>}
                <button className="btn btn-lg btn-da btn-block mt-3" type="submit">
                    Подтвердить
                </button>
            </AvForm>
        </>
    )
}

export default LoanConfirmation

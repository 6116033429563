import React, { useState, useEffect, ReactElement, useRef, MouseEvent } from "react"
import { useHistory, Link } from "react-router-dom"
import { Spinner, Alert, Modal, ModalBody, ModalFooter, ModalHeader, Label } from "reactstrap"
import { AvForm, AvGroup, AvInput, AvFeedback } from "availity-reactstrap-validation"
import logo from "../img/logo-da.png"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPhone } from "@fortawesome/free-solid-svg-icons"
import { faFilePdf, faUser } from "@fortawesome/free-regular-svg-icons"
import { parse, isAfter } from "date-fns"
import GetLoan from "./GetLoan"
import LoanConfirmation from "./LoanConfirmation"
import Loan, { IApprovalInfo } from "./Loan"
import ZKFrame from "./ZKFrame"
import { getErrorMessage, numberWithSpaces } from "../utils"
import { Helmet } from "react-helmet"
import { detect } from "detect-browser"

const baseUri = process.env.REACT_APP_BASEURI
const userAgent = navigator.userAgent || navigator.vendor
const android = /android/i.test(userAgent)
const browser = detect()

interface ISchedule {
    date_schedule: string
    sum_schedule: string
    procent_schedule: string
}

interface IInfo {
    FIO?: string
    TelefonSot?: string
    StatusCredit?: string
    summa_credit?: string
    procent_credit?: string
    time_credit?: string
    begindate_credit?: string
    endDate_credit?: string
    amount_debt?: string
    root_debt?: string
    sum_procent_debt?: string
    procent_debt?: string
    psk_credit?: string
    psk_credit_full?: string
    schedule?: Array<ISchedule>
    minCountDay?: string
    maxCountDay?: string
    appealStatus?: string
    ApprovalInfo?: IApprovalInfo
    NumCard?: string
    minAmmount_debet?: string
    minSumma?: string
    maxSumma?: string
}

const headers = {
    "Content-Type": "application/json",
    Pragma: "no-cache",
    "Cache-Control": "no-cache",
}

const LK: React.FC = () => {
    const history = useHistory()
    const [info, setInfo] = useState<IInfo>()
    const [error, setError] = useState("")
    const [message, setMessage] = useState("")
    const amountInput = useRef<HTMLInputElement>(null)
    const [modal, setModal] = useState(false)
    const toggle = () => setModal(!modal)
    const [amount, setAmount] = useState<string>("")

    let timer: number

    useEffect(() => {
        document.body.className = "lk"
        const fn = async () => {
            const res = await fetch(`${baseUri}/api/info`, {
                method: "GET",
                headers,
                credentials: "include",
            })

            if (res.status !== 200) {
                localStorage.removeItem("signIn")
                return history.push("/")
            }

            const info = await res.json()

            if (info.StatusCredit !== "0" && (info.appealStatus === "1" || info.appealStatus === "5")) {
                getStatusApproval()
            } else if (info.StatusCredit !== "0" && info.appealStatus === "2") {
                const res = await fetch(`${baseUri}/api/getApprovalInfo`, {
                    method: "GET",
                    headers,
                    credentials: "include",
                })

                if (res.status !== 200) {
                    return setError(getErrorMessage(res.status))
                }
                const json = await res.json()
                info.ApprovalInfo = json
            }

            setInfo(info)
        }

        const signIn = localStorage.getItem("signIn")
        if (!signIn) {
            return history.push("/")
        }

        fn()
    }, [history])

    const handleGetCredit = async (values: any) => {
        try {
            const res = await fetch(`${baseUri}/api/getCredit`, {
                method: "POST",
                headers,
                credentials: "include",
                body: JSON.stringify(values),
            })

            if (res.status !== 200) {
                setMessage("")
                return setError(getErrorMessage(res.status))
            }
            const { StatusAppeal } = await res.json()
            if (StatusAppeal === "OK") {
                return setInfo({ ...info, appealStatus: "0" })
            }

            return setError("Ошибка")
        } catch (err) {
            console.error(err)
            return setError("Сервер не отвечает. Попробуйте позже отправить заявку.")
        }
    }

    const handleSMSConfirmation = async (values: any, type: string) => {
        try {
            const res = await fetch(`${baseUri}/api/smsConfirmation`, {
                method: "POST",
                headers,
                credentials: "include",
                body: JSON.stringify({ ...values, type }),
            })

            if (res.status !== 200) {
                setMessage("")
                return setError(getErrorMessage(res.status))
            }
            const { StatusAppealConsent, StatusReceivingCreditConsent } = await res.json()
            if (StatusAppealConsent === "OK") {
                setInfo({ ...info, appealStatus: "1" })
                return getStatusApproval()
            }

            if (StatusReceivingCreditConsent === "OK") {
                setInfo({ ...info, appealStatus: "5" })
                return getStatusApproval()
            }

            return setError("Ошибка")
        } catch (err) {
            console.error(err)
            return setError("Сервер не отвечает. Попробуйте позже отправить заявку.")
        }
    }

    const handleGetCode = async (type: string) => {
        try {
            const res = await fetch(`${baseUri}/api/getSMSCode`, {
                method: "POST",
                headers,
                credentials: "include",
                body: JSON.stringify({ type }),
            })
            if (res.status !== 200) {
                setMessage("")
                return setError(getErrorMessage(res.status))
            }
            const { StatusReceivingSmsAppeal, StatusReceivingSmsApproval } = await res.json()
            if (StatusReceivingSmsAppeal === "OK") {
                setError("")
                return setMessage("Код подтверждения выслан")
            }
            if (StatusReceivingSmsApproval === "OK") {
                setError("")
                return setMessage("Код подтверждения выслан")
            }
            return setError("Ошибка")
        } catch (err) {
            console.error(err)
            return setError("Сервер не отвечает. Попробуйте позже отправить заявку.")
        }
    }

    const getStatusApproval = () => {
        clearTimeout(timer)
        timer = window.setTimeout(async () => {
            const newInfo: IInfo = {}
            const res = await fetch(`${baseUri}/api/getApprovalStatus`, {
                method: "GET",
                headers,
                credentials: "include",
            })

            if (res.status !== 200) {
                return setError(getErrorMessage(res.status))
            }
            const { StatusApproval } = await res.json()
            if (StatusApproval === "1" || StatusApproval === "5") {
                return getStatusApproval()
            }

            newInfo.appealStatus = StatusApproval

            if (StatusApproval === "2") {
                const res = await fetch(`${baseUri}/api/getApprovalInfo`, {
                    method: "GET",
                    headers,
                    credentials: "include",
                })

                if (res.status !== 200) {
                    return setError(getErrorMessage(res.status))
                }
                const json = await res.json()
                newInfo.ApprovalInfo = json
            }

            if (StatusApproval === "6") {
                document.location.reload()
            }

            setInfo((info) => ({ ...info, ...newInfo }))
        }, 10000)
    }

    const handleReceiveCredit = async (values: any) => {
        try {
            const res = await fetch(`${baseUri}/api/receiveCredit`, {
                method: "POST",
                headers,
                credentials: "include",
                body: JSON.stringify({ ...values }),
            })

            if (res.status !== 200) {
                setMessage("")
                return setError(getErrorMessage(res.status))
            }
            const { StatusReceivingCredit } = await res.json()
            if (StatusReceivingCredit === "OK") {
                return setInfo({ ...info, appealStatus: "4" })
            }

            return setError("Ошибка")
        } catch (err) {
            console.error(err)
            return setError("Сервер не отвечает. Попробуйте позже отправить заявку.")
        }
    }

    const exit = () => {
        localStorage.removeItem("signIn")
        return history.push("/")
    }

    const openCoronaPay = (e: MouseEvent<HTMLButtonElement>) => {
        e.preventDefault()
        const amount = amountInput.current?.value
        if (amount) {
            setAmount(amount)
        }
        toggle()
    }

    if (!info) {
        return (
            <div className="d-flex flex-column justify-content-center align-items-center vh-100">
                <Spinner className="danger" />
            </div>
        )
    }

    const {
        FIO,
        TelefonSot,
        StatusCredit,
        summa_credit,
        procent_credit,
        time_credit,
        begindate_credit,
        endDate_credit,
        amount_debt,
        root_debt,
        sum_procent_debt,
        procent_debt,
        psk_credit,
        schedule = [],
        appealStatus,
        psk_credit_full = "",
        ApprovalInfo = {},
        NumCard,
        minAmmount_debet,
    } = info

    const now = new Date()
    const date = parse(endDate_credit!, "dd.MM.yyyy", new Date())

    let stages: React.ReactElement = <></>

    switch (appealStatus) {
        case "-1":
        case "6":
            stages = <GetLoan info={info} handleSubmit={handleGetCredit} error={error} message={message} />
            break
        case "0":
            stages = (
                <LoanConfirmation
                    error={error}
                    message={message}
                    handleSubmit={handleSMSConfirmation}
                    handleGetCode={handleGetCode}
                    label="Подтверждение подачи заявки"
                    type="request"
                    text={
                        <div>
                            На номер{" "}
                            <span className="text-danger">
                                {TelefonSot && /^\+7\d{10}$/.test(TelefonSot)
                                    ? TelefonSot.replace(/^\+7(\d{3})(\d{3})(\d{2})(\d{2})$/, "+7 ($1) $2-$3-$4")
                                    : TelefonSot}
                            </span>{" "}
                            было отправлено смс с кодом подтверждения. Введите данный код, чтобы продолжить оформление
                            заявки.
                        </div>
                    }
                />
            )
            break
        case "1":
            stages = <Alert>Ваша заявка находится в обработке</Alert>
            break
        case "2":
            stages = <Loan info={ApprovalInfo} handleSubmit={handleReceiveCredit} />
            break
        case "3":
            stages = <Alert>Ваша заявка отклонена</Alert>
            break
        case "4":
            stages = (
                <LoanConfirmation
                    error={error}
                    message={message}
                    handleSubmit={handleSMSConfirmation}
                    handleGetCode={handleGetCode}
                    label="Подтверждение получения займа"
                    type="loan"
                    text={
                        <div>
                            На номер{" "}
                            <span className="text-danger">
                                {TelefonSot && /^\+7\d{10}$/.test(TelefonSot)
                                    ? TelefonSot.replace(/^\+7(\d{3})(\d{3})(\d{2})(\d{2})$/, "+7 ($1) $2-$3-$4")
                                    : TelefonSot}
                            </span>{" "}
                            было отправлено смс с кодом подтверждения. Введите данный код, чтобы подтвердить получения
                            займа.
                        </div>
                    }
                />
            )
            break
        case "5":
            stages = <Alert>Ваш займ подтвержден. Ожидайте получения займа.</Alert>
            break
        default:
            break
    }

    return (
        <>
            <Helmet>
                <title>Личный кабинет "Деньги Актив"</title>
            </Helmet>
            <main role="main">
                <div className="d-flex flex-column flex-md-row align-items-center p-3 px-md-4 mb-3 bg-white border-bottom shadow-sm">
                    <div className="header-da container d-lg-flex d-xl-flex d-md-flex justify-content-between">
                        <img className="my-0 mr-md-auto font-weight-normal logo-da" src={logo} alt="ДеньгиАктив" />
                        <div className="contact-da">
                            <div className="phone-da">
                                <p>
                                    <a href="tel:88007000225">8 800 700-02-25</a>
                                </p>
                                <p>ЗВОНОК ПО РОССИИ БЕСПЛАТНЫЙ</p>
                            </div>
                            <button className="btn-da" onClick={exit}>
                                Выход
                            </button>
                        </div>
                    </div>
                </div>
                <div className="container">
                    <div className="row mb-2">
                        <div className="col-md-4">
                            <div className="bg-da row no-gutters border rounded overflow-hidden flex-md-row mb-4 shadow-sm h-md-250 position-relative">
                                <div className="col p-4 d-flex flex-column position-static">
                                    <h6 className="mb-0">
                                        <span className="badge badge-success mr-1">
                                            <FontAwesomeIcon icon={faUser} />
                                        </span>
                                        {FIO}
                                    </h6>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="bg-da row no-gutters border rounded overflow-hidden flex-md-row mb-4 shadow-sm h-md-250 position-relative">
                                <div className="col p-4 d-flex flex-column position-static">
                                    <h6 className="mb-0">
                                        <span className="badge badge-success mr-1">
                                            <FontAwesomeIcon icon={faPhone} />
                                        </span>
                                        {TelefonSot && /^\+7\d{10}$/.test(TelefonSot)
                                            ? TelefonSot.replace(
                                                  /^\+7(\d{3})(\d{3})(\d{2})(\d{2})$/,
                                                  "+7 ($1) $2-$3-$4",
                                              )
                                            : TelefonSot}
                                    </h6>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="bg-da row no-gutters border rounded overflow-hidden flex-md-row mb-4 shadow-sm h-md-250 position-relative">
                                <div className="col p-4 d-flex flex-column position-static">
                                    <h6 className="mb-0">
                                        <span className="badge badge-success mr-1">
                                            <FontAwesomeIcon icon={faFilePdf} />
                                        </span>
                                        {StatusCredit === "0" ? (
                                            android ? (
                                                <Link to="pdf/contract.pdf">Скачать договор</Link>
                                            ) : (
                                                <a
                                                    href={`${baseUri}/api/contract.pdf`}
                                                    role="button"
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                >
                                                    Скачать договор
                                                </a>
                                            )
                                        ) : (
                                            "Активных займов нет"
                                        )}
                                    </h6>
                                </div>
                            </div>
                        </div>
                    </div>
                    {StatusCredit === "0" ? (
                        <>
                            <div className="row mb-2">
                                <div className="col-md-12">
                                    <div className="bg-da row no-gutters border rounded overflow-hidden flex-md-row mb-4 shadow-sm h-md-250 position-relative">
                                        <div className="col p-4 d-flex flex-column position-static">
                                            <div className="row">
                                                <h3 className="col-md-6">Данные по займу</h3>
                                                <h5 className="col-md-6">
                                                    Полная стоимость кредита:{" "}
                                                    {psk_credit && (
                                                        <span>
                                                            {`${numberWithSpaces(psk_credit)} процентов годовых `}
                                                            <span className="credit-full">
                                                                ({psk_credit_full} процентов годовых)
                                                            </span>
                                                        </span>
                                                    )}
                                                </h5>
                                                <div className="col-md-6 pt-2">
                                                    <ul>
                                                        <li>Сумма займа: {numberWithSpaces(summa_credit)} руб.</li>
                                                        <li>Процент займа: {procent_credit}%</li>
                                                        <li>Срок займа: {time_credit} дней</li>
                                                        <li>Дата выдачи: {begindate_credit} года</li>
                                                        <li>Дата возврата: {endDate_credit} года</li>
                                                    </ul>
                                                </div>
                                                <div className="col-md-6 pt-md-2">
                                                    <ul>
                                                        <li>
                                                            <span className={isAfter(now, date) ? "danger-da" : ""}>
                                                                Сумма задолжности:{" "}
                                                                {amount_debt && `${numberWithSpaces(amount_debt)} руб.`}
                                                            </span>
                                                        </li>
                                                        <li>
                                                            Сумма корня:{" "}
                                                            {root_debt && `${numberWithSpaces(root_debt)} руб.`}
                                                        </li>
                                                        <li>
                                                            Сумма начисленных процентов:
                                                            {sum_procent_debt && ` ${sum_procent_debt} руб.`}
                                                        </li>
                                                        <li>
                                                            Процент в день: {procent_debt && `${procent_debt} руб.`}
                                                        </li>
                                                    </ul>
                                                    {NumCard && (
                                                        <>
                                                            <button
                                                                className="btn-da btn"
                                                                style={{ marginLeft: "22px" }}
                                                                onClick={toggle}
                                                            >
                                                                Оплатить
                                                            </button>
                                                            <Modal
                                                                isOpen={!!amount}
                                                                toggle={() => setAmount("")}
                                                                centered={true}
                                                                size="xl"
                                                            >
                                                                <ModalHeader toggle={() => setAmount("")}></ModalHeader>
                                                                <ZKFrame
                                                                    query={`prefill={"account":"${NumCard}","payee":"${FIO!.toUpperCase()}","amount":"${amount}", "phone":"${TelefonSot!.replace(
                                                                        "+",
                                                                        "",
                                                                    )}"}`}
                                                                />
                                                            </Modal>

                                                            {browser?.name === "ie" ? (
                                                                <Modal
                                                                    isOpen={modal}
                                                                    toggle={toggle}
                                                                    centered={true}
                                                                    size={"lg"}
                                                                >
                                                                    <ModalHeader toggle={toggle}></ModalHeader>
                                                                    <ModalBody>
                                                                        К сожалению ваш браузер устарел. Для совершения
                                                                        платежа воспользуйтесь другим браузером.
                                                                        Например: Google Chrome, Яндекс браузер или
                                                                        Edge. Приносим свои извинения за доставленные
                                                                        неудобства.
                                                                    </ModalBody>
                                                                </Modal>
                                                            ) : (
                                                                <Modal isOpen={modal} toggle={toggle} centered={true}>
                                                                    <ModalHeader toggle={toggle}>
                                                                        Укажите сумму платежа
                                                                    </ModalHeader>
                                                                    <ModalBody>
                                                                        <AvForm>
                                                                            <AvGroup className="form-label-group">
                                                                                <AvInput
                                                                                    type="number"
                                                                                    id="amount"
                                                                                    name="amount"
                                                                                    min={minAmmount_debet}
                                                                                    max={amount_debt}
                                                                                    value={amount_debt?.replace(
                                                                                        ",",
                                                                                        ".",
                                                                                    )}
                                                                                    placeholder="Сумма платежа"
                                                                                    ref={amountInput}
                                                                                    required
                                                                                />
                                                                                <Label for="code">Сумма платежа</Label>
                                                                                <AvFeedback>
                                                                                    Неверная сумма платежа
                                                                                </AvFeedback>
                                                                            </AvGroup>
                                                                        </AvForm>
                                                                    </ModalBody>
                                                                    <ModalFooter className="d-flex justify-content-between">
                                                                        <button className="btn-da btn" onClick={toggle}>
                                                                            Отмена
                                                                        </button>
                                                                        <button
                                                                            className="btn-da btn"
                                                                            onClick={openCoronaPay}
                                                                        >
                                                                            Оплатить
                                                                        </button>
                                                                    </ModalFooter>
                                                                </Modal>
                                                            )}
                                                        </>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="row mb-2">
                                <div className="col-md-12">
                                    <div className="bg-da row no-gutters border rounded overflow-hidden flex-md-row mb-4 shadow-sm h-md-250 position-relative">
                                        <div className="col p-4 d-flex flex-column position-static">
                                            <h3 className="mb-0">График платежей</h3>
                                            <div className="row p-3">
                                                <table className="table">
                                                    <thead>
                                                        <tr>
                                                            <th scope="col">Дата</th>
                                                            <th scope="col">Сумма к выплате</th>
                                                            <th scope="col">Начисляемый процент</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {schedule.map(
                                                            ({ date_schedule, sum_schedule, procent_schedule }) => {
                                                                return (
                                                                    <tr key={`k-${date_schedule}`}>
                                                                        <th scope="row">{date_schedule}</th>
                                                                        <td>{numberWithSpaces(sum_schedule)}</td>
                                                                        <td>{procent_schedule}</td>
                                                                    </tr>
                                                                )
                                                            },
                                                        )}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                    ) : (
                        <div className="row mb-2">
                            <div className="col-md-6 offset-md-3">
                                <div className="bg-da row no-gutters border rounded overflow-hidden flex-md-row mb-4 shadow-sm h-md-250 position-relative">
                                    <div className="col p-4 d-flex flex-column position-static">{stages}</div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </main>
        </>
    )
}

export default LK

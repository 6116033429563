import React from "react"
import { MobilePDFReader } from "react-read-pdf"
import { RouteComponentProps } from "react-router-dom"
const baseUri = process.env.REACT_APP_BASEURI

interface MatchParams {
    file: string
}

interface IProps extends RouteComponentProps<MatchParams> {}

const Contract = ({ match }: IProps) => {
    return (
        <div style={{ overflow: "scroll", height: "100%", width: "100%" }}>
            <MobilePDFReader url={`${baseUri}/api/${match.params.file}`} isShowHeader={false} />
        </div>
    )
}

export default Contract

import React, { useState } from "react"
import {
    AvForm,
    AvGroup,
    AvInput,
    AvField,
    AvCheckboxGroup,
    AvCheckbox,
    AvFeedback,
} from "availity-reactstrap-validation"
import { Label, Alert, CustomInput } from "reactstrap"
import numberToWordsRu from "number-to-words-ru"

interface IInfo {
    minSumma?: string
    maxSumma?: string
    maxCountDay?: string
    minCountDay?: string
}
interface IGetLoan {
    info: IInfo
    handleSubmit: (values: any) => void
    error: string
    message: string
}

const GetLoan: React.FC<IGetLoan> = props => {
    const [summaCredits, setSummaCredits] = useState("20000")
    const [countDay, setCountDay] = useState("20")
    const { maxSumma, minSumma, maxCountDay, minCountDay = "1" } = props.info

    const handleChangeSummaCredit = (e: React.FormEvent<HTMLInputElement>) => {
        e.preventDefault()
        setSummaCredits(e.currentTarget.value)
    }

    const handleChangeCountDay = (e: React.FormEvent<HTMLInputElement>) => {
        e.preventDefault()
        setCountDay(e.currentTarget.value)
    }

    const handleSubmit = async (
        e: React.FormEvent<HTMLInputElement>,
        errors: any,
        { countDay, promoCod, personalData, loanRules }: any,
    ) => {
        e.preventDefault()
        if (!errors || !errors.length) {
            props.handleSubmit({
                summaCredits,
                countDay,
                promoCod,
                personalData: personalData[0],
                loanRules: loanRules[0],
            })
        }
    }

    return (
        <>
            <h3 className="mb-0">Получить займ</h3>
            <AvForm onSubmit={handleSubmit} className="form-signin">
                <AvGroup className="calculator_01_left">
                    <AvInput
                        type="number"
                        id="summaCredits"
                        name="summaCredits"
                        min={minSumma}
                        max={maxSumma}
                        value={summaCredits}
                        step="1000"
                        disabled={true}
                        style={{
                            float: "right",
                            margin: 0,
                            padding: 0,
                            width: "100px",
                            height: "30px",
                            textAlign: "right",
                            backgroundColor: "unset",
                        }}
                    />

                    <AvField
                        tag={CustomInput}
                        type="range"
                        min={minSumma}
                        max={maxSumma}
                        value={summaCredits}
                        step="1000"
                        id="summaCreditsRange"
                        name="summaCreditsRange"
                        className="calculator_01_range"
                        label="Сумма займа"
                        required
                        onChange={handleChangeSummaCredit}
                    />
                    <div
                        className="d-flex justify-content-between"
                        style={{
                            marginTop: "-15px",
                        }}
                    >
                        <span>{minSumma} руб.</span>
                        <span>{maxSumma} руб.</span>
                    </div>
                </AvGroup>
                <AvGroup className="calculator_01_left">
                    <AvInput
                        type="number"
                        id="countDay"
                        name="countDay"
                        min={minCountDay}
                        max={maxCountDay}
                        value={countDay}
                        step="1"
                        disabled={true}
                        style={{
                            float: "right",
                            margin: 0,
                            padding: 0,
                            width: "100px",
                            height: "30px",
                            textAlign: "right",
                            backgroundColor: "unset",
                        }}
                    />

                    <AvField
                        tag={CustomInput}
                        type="range"
                        min={minCountDay}
                        max={maxCountDay}
                        value={countDay}
                        step="1"
                        id="countDayRange"
                        name="countDayRange"
                        className="calculator_01_range"
                        label="Срок займа"
                        required
                        onChange={handleChangeCountDay}
                    />
                    <div
                        className="d-flex justify-content-between"
                        style={{
                            marginTop: "-15px",
                        }}
                    >
                        <span>1 день</span>
                        <span>
                            {numberToWordsRu.convert("30", {
                                currency: {
                                    currencyNameCases: ["день", "дня", "дней"],
                                    fractionalPartNameCases: ["", "", ""],
                                    currencyNounGender: {
                                        integer: 0,
                                        fractionalPart: 1,
                                    },
                                },
                                convertMinusSignToWord: false,
                                showNumberParts: {
                                    fractional: false,
                                },
                                convertNumbertToWords: {
                                    integer: false,
                                },
                                showCurrency: {
                                    fractional: false,
                                },
                            })}
                        </span>
                    </div>
                </AvGroup>
                <AvGroup className="form-label-group">
                    <AvInput
                        type="text"
                        id="promoCod"
                        name="promoCod"
                        placeholder="Промокод"
                        pattern="^[A-Za-zА-Яа-яЁё0-9]*$"
                    />
                    <Label for="promoCod">Промокод</Label>
                    <AvFeedback>Неверный промокод</AvFeedback>
                </AvGroup>
                <AvCheckboxGroup
                    name="personalData"
                    required
                    errorMessage="Пожалуйста ознакомьтесь и подтвердите своё согласие"
                >
                    <AvCheckbox
                        customInput
                        label={
                            <>
                                Я ознакомлен(а) и принимаю{" "}
                                <a href="https://dengiaktiv.ru/wp-content/uploads/2019/12/Soglashenie-ob-ispolzovanii-ASP.pdf">
                                    Соглашение об использовании АСП
                                </a>
                                , подтверждаю, что ознакомился(ась) с{" "}
                                <a href="https://dengiaktiv.ru/wp-content/uploads/2019/12/prav-obr-pd-alekta-chelyab.pdf">
                                    Правилами обработки персональных данных
                                </a>{" "}
                                и даю{" "}
                                <a href="https://dengiaktiv.ru/wp-content/uploads/2019/12/soglas-obr-pd-alekta-chelyab.pdf">
                                    согласие на их обработку и хранение.
                                </a>
                            </>
                        }
                        value={true}
                    />
                </AvCheckboxGroup>
                <AvCheckboxGroup
                    name="loanRules"
                    required
                    errorMessage="Пожалуйста ознакомьтесь и подтвердите своё согласие"
                >
                    <AvCheckbox
                        customInput
                        label={
                            <>
                                Я ознакомлен(а) и принимаю{" "}
                                <a href="https://dengiaktiv.ru/wp-content/uploads/2019/12/Obshhie-usloviya-dogovora-ot-20.12.2019g..pdf">
                                    Общие условия потребительского займа
                                </a>{" "}
                                и{" "}
                                <a href="https://dengiaktiv.ru/wp-content/uploads/2019/12/Pravila-predostavleniya-zajmov-ot-20.12.2019g..pdf">
                                    Правила предоставления займов
                                </a>
                                . Прошу принять моё заявление о предоставлении микрозайма.
                            </>
                        }
                        value={true}
                    />
                </AvCheckboxGroup>
                {props.error && <Alert color="danger">{props.error}</Alert>}
                {props.message && <Alert>{props.message}</Alert>}
                <button className="btn btn-lg btn-da btn-block mt-3" type="submit">
                    Отправить
                </button>
            </AvForm>
        </>
    )
}

export default GetLoan
